<template>
  <div class="participants-list-container">
    <div class="conference-info">
      <div class="copyLinkContainer">
        <div class="heading">Invite Link:</div> 
        <div class="notHeading">{{call.inviteLink}}</div>
        <div class="copyLinkButton" @click="copyLink()">Copy Link</div>
      </div>
      <div class="infoContainer">
        <div class="heading">Name:</div> 
        <div class="notHeading">{{call.conference.name}}</div>
      </div>
      <div class="infoContainer">
        <div class="heading">Contact Number:</div> 
        <div class="notHeading">{{call.conference.phone_number}}</div>
      </div>
      <div class="infoContainer">
        <div class="heading">Meeting ID:</div> 
        <div class="notHeading">{{call.meeting_id}}</div>
      </div>
      <div class="infoContainer">
        <div class="heading">Time:</div> 
        <div class="notHeading">{{call.time | duration_format}}</div>
      </div>
    </div>
    <div class="participantsListHeading">
      Participants
    </div>
    <div class="bill-main participantsListContainer">
      <Participant :call="call" />
      <Participant v-for="participant in call.participants" :key="participant._id" :participant="participant" :call="call" />
    </div>
  </div>
</template>

<script>
import Participant from './Participant'
export default {
  name: 'Participants',
  components: {
    Participant
  },
  props: {
    call: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
  },
  methods: {
    copyLink(){
      let vm = this
      this.call.copyLink()
      .then(()=>{
        vm.$toast.open({
          type: 'success',
          message: 'Copied Successfully'
        })
      })
      .catch((ex)=>{
        vm.$toast.open({
          type: 'error',
          message: ex.message || 'Failed to Copied'
        })
      })
    },
  },
}
</script>

<style scoped>
.participants-list-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.conference-info {
  color: white;
  display: flex;
  flex-direction: column;
}
</style>