<template>
  <div 
    class="bill-track"
    :class="{
      'speaker': isSpeaker
    }"
  >
    <div class="bill-info-main">
      <div class="bill-info v2">
        <div v-if="isHandRise">{{call.defaultEmoji.icon}}</div>
        <p>{{ displayName }}</p>
        <b-icon :icon="audioMuted?'mic-mute-fill':'mic-fill'" class="micIcon" />
        <b-icon :icon="videoMuted?'camera-video-off-fill':'camera-video-fill'" class="cameraIcon" />
        <b-icon v-if="isHold" icon="pause-fill" class="pauseIcon" />
        <b-icon v-if="isRecording" icon="record-circle-fill" />
        <div v-if="!audioMuted && audioTrack && audioTrack.stream" class="bill-wave-visualize" v-visualize="audioTrack.stream">
          <div v-for="(data,index) in waves" :key="index" :data-value="data" />
        </div>
      </div>
    </div>
    <div v-if="videoMuted" class="bill-video-mute-container-v2">
      <div class="bill-image-container-v2">
        <img :src="avatar" >
      </div>
      <span class="error-message" v-if="videoErrorMessage">{{videoErrorMessage}}</span>
      <div v-if="isHold" class="bill-track-hold-v2">On Hold</div>
    </div>
    <video autoplay="1" :class="isMirrorVideo ? 'mirror-video' : ''" v-attach-track="videoTrack" />
    <audio autoplay="1" v-app-sink-id="call.audio.speaker_id" v-app-volume="audioLevel" v-attach-track="audioTrack" />
  </div>
</template>

<script>
import { jitsi_participant_info } from '@/bootstrap/mixins/jitsiParticipantInfo'
export default {
  name: "Track",
  mixins: [jitsi_participant_info],
  props: {
    participant: {
      type: Object,
    },
    call: {
      type: Object,
      default: ()=>{return{}},
      require: true,
    },
  },
};
</script>

<style lang="scss">
.bill-wave-visualize {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  div {
    display: inline-block;
    width: 3px;
    height: 15px;
    margin: 0 1px;
    background: #FFFFFF;
    transform: scaleY(.5)
  }
}
.bill-track {
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  background-color: #555555;
  &.speaker {
    border-width: 5px;
    border-color: #5576d1;
    border-style: solid;
    transition: border-width 0.3s, border-color 0.3s;
  }
  .bill-info-main {
    height: auto !important;
    width: 100% !important;
    top: 5px;
    z-index: 3;
    position: absolute;
    display: flex;
    justify-content: center;
    .bill-info {
      color: white;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 5px;
      backdrop-filter: blur(30px);
      --webkit-backdrop-filter: blur(30px);
      background-color: rgba(0, 0, 0, 0.15);
      p {
        margin: 0;
      }
    }
  }
  .bill-video-mute-container-v2 {
    height: 100% !important;
    width: 100% !important;
    border-radius: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .bill-image-container-v2 {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #000000;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .error-message {
      color: red;
      font-size: 25px;
    }
    .bill-track-hold-v2 {
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #000000;
      color: #ffffff;
      padding: 5px;
    }
  }
  .video-mute-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #555555;
    div {
      height: 150px;
      width: 150px;
      border-radius: 50%;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .bill-track-hold{
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        background-color: black;
        height: 170px;
        width: 170px;
        position: absolute;
        left: auto;
        opacity: 0.5;
      }
      p {
        font-size: 28px;
        font-weight: bold;
        background-color: #00000087;
        color: white;
        border-radius: 12px;
      }
    }
  }
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
