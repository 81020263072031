<template>
  <div class="main1">
    <div class="bill-tracks-container-main">
      <Tracks :call="call" />
      <Actions 
        :call="call" 
        :chat="conditions.info==infosPage.chat" 
        :participants="conditions.info==infosPage.participants"
        :white_board="conditions.info==infosPage.whiteboard" 
        @toggle-chat="conditions.info=conditions.info==infosPage.chat ? '':infosPage.chat"
        @toggle-participants="conditions.info=conditions.info==infosPage.participants ? '':infosPage.participants" 
        @toggle-white-board="conditions.info=conditions.info==infosPage.whiteboard ? '':infosPage.whiteboard" 
      />
      <div :class="`emojiAnimation ${call.reactEmoji.classC}`" v-if="call.isReaction && call.reactEmoji" >
        <span>{{call.reactEmoji.icon}}</span>
      </div>
    </div>
    <Info 
      @close="conditions.info=''"
      :page="conditions.info" 
      :call="call" 
    />
  </div>
</template>

<script>
import Tracks from './tracks/index.vue'
import Info from './info/index.vue'
import Actions from './Actions.vue'
let infos_page = {
  chat: 'chat',
  participants: 'participants',
  whiteboard: 'whiteboard',
}
export default {
  name: 'Conference',
  components: {
    Info,
    Tracks,
    Actions,
  },
  props: {
    call: {
      type: Object,
      default: ()=>{return{}},
      require: true,
    },
  },
  data(){
    return {
      conditions: {
        info: '',
      },
    }
  },
  computed: {
    infosPage(){ return infos_page },
  },
  mounted(){
    this.call.createlocaltracks()
    this.call.join()
  }
}
</script>

<style scoped>
.main1 {
  height: inherit;
  width: inherit;
  display: flex;
  position: relative;
}
.bill-tracks-container-main {
  display: flex;
  flex: 1;
  position: relative;
}
</style>