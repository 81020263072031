<template>
  <!-- meeting -->
  <div v-if="conditions.screen==screens.meeting" class="dialer-login-form ">
    <b-form  @submit.prevent="getroomdetail()">
      <div class="dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 ">
        <div class="signupLoginV2-container">
          <div class="signupLoginV2-section-1 meeting"></div>
          <div class="signupLoginV2-section-2">
            <div class="wrap ">
              <div class="signupLoginV2-section-2-left wd-38-5 my-0 justify-content-center">
                <span class="signupLoginV2-sub-heading">Welcome to VoIP Business</span>
                <h4 class="signupLoginV2-main-heading">
                  <span>Enter your  </span><br/>
                  <span>Conference details</span><br/>
                </h4>
                <p class="signupLoginV2-sub-heading color-primary mt-32px text-adjust mb-0 wd-85">
                  Enter Meeting Details
                </p>
              </div>
              <div class="signupLoginV2-section-2-right">
                <b-alert class="mb-32px" :show="api.meeting.error_message?true:false" variant="danger">
                  {{ api.meeting.error_message }}
                </b-alert>

                <div class="input-shadow-v2 with-error">
                  <label>Enter meeting id</label>
                  <div :class="`input-shadow-v2-inner ${api.meeting.send?'disabled':''}`">
                    <b-form-input id="input-1" :disabled="api.meeting.send" v-model="forms.meeting.meeting_id" type="number" placeholder=""/>
                  </div>
                  <template v-if="(forms.meeting.submitted && $v.forms.meeting.meeting_id.$invalid) || (api.meeting.error.errors && api.meeting.error.errors.meeting_id)">
                    <b-icon id="su-email" icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                    <div class="errorTemplate-v2">
                      <template v-if="forms.meeting.submitted">
                        <p v-if="$v.forms.meeting.meeting_id.$invalid" class=" animated bounceIntop">
                          <span v-if="!$v.forms.meeting.meeting_id.required">* meeting id is required</span>
                        </p>
                      </template>
                      <template v-if="api.meeting.error.errors">
                        <p v-if="api.meeting.error.errors.meeting_id" class=" animated bounceIntop">
                          <span v-for="(error_message, index) in api.meeting.error.errors.meeting_id" :key="index">* {{ error_message }}</span>
                        </p>
                      </template>
                    </div>
                  </template>
                </div>

                <div class="formSubmit mt-32px">
                  <div class="button-container-v2">
                    <div class="button-container-inner">
                      <b-button class="login-button-v2" type="submit" >
                        <app-spinner v-if="api.meeting.send" />
                        <template v-else>Join</template>
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="login-bottom-line-container with-text fixed-bottom position-absolute">
                  <div class="login-bottom-line-before"></div>
                  <div class="login-bottom-line">
                    <span class="">Back to </span>
                    <a @click="$router.push({name:'login'});" class="mx-2 font-weight-bold font-size-14 color-primary">Sign In</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
  <!-- check_password -->
  <div v-else-if="conditions.screen==screens.check_password" class="dialer-login-form ">
    <b-form  @submit.prevent="checkpassword()">
      <div class="dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 ">
        <div class="signupLoginV2-container">
          <div class="signupLoginV2-section-1 meeting"></div>
          <div class="signupLoginV2-section-2">
            <div class="wrap ">
              <div class="signupLoginV2-section-2-left wd-38-5 mt-0 justify-content-center">
                <span class="signupLoginV2-sub-heading">Welcome to VoIP Business</span>
                <h4 class="signupLoginV2-main-heading">
                  <span>Enter your  </span><br/>
                  <span>Conference details</span><br/>
                </h4>
                <p class="signupLoginV2-sub-heading color-primary mt-32px text-adjust mb-0 wd-85">
                  Enter Meeting Details
                </p>
              </div>
              <div class="signupLoginV2-section-2-right">

                <b-alert :show="api.check_password.error_message?true:false" class="mt-5" variant="danger">
                  {{ api.check_password.error_message }}
                </b-alert>

                <div class="input-shadow-v2 with-error">
                  <label>Enter username</label>
                  <div :class="`input-shadow-v2-inner ${api.check_password.send?'disabled':''}`">
                    <b-form-input id="input-1" :disabled="api.check_password.send" v-model="forms.check_password.username" type="text" placeholder=""/>
                  </div>
                  <template v-if="(forms.check_password.submitted && $v.forms.check_password.username.$invalid) || (api.check_password.error.errors && api.check_password.error.errors.username)">
                    <b-icon id="su-email" icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                    <div class="errorTemplate-v2">
                      <template v-if="forms.check_password.submitted">
                        <p v-if="$v.forms.check_password.username.$invalid" class=" animated bounceIntop">
                          <span v-if="!$v.forms.check_password.username.required">* Username is required</span>
                          <span v-if="!$v.forms.check_password.username.minLength">* Username is minimum 8 character</span>
                          <span v-if="!$v.forms.check_password.username.maxLength">* Username is maximum 32 character</span>
                        </p>
                      </template>
                      <template v-if="api.check_password.error.errors">
                        <p v-if="api.check_password.error.errors.username" class=" animated bounceIntop">
                          <span v-for="(error_message, index) in api.check_password.error.errors.username" :key="index">* {{ error_message }}</span>
                        </p>
                      </template>
                    </div>
                  </template>
                </div>

                <div class="input-shadow-v2 mt-16px with-error">
                  <label>Password</label>
                  <div :class="`input-shadow-v2-inner password ${api.check_password.send?'disabled':''}`">
                    <b-form-input id="input-2" :disabled="api.check_password.send" :type="conditions.check_password_pass_show?'text':'password'" v-model="forms.check_password.password" placeholder="password" />
                    <span class="pass-icon">
                      <b-icon class="dialer-showpassword-icon cursor_pointer" :icon="conditions.check_password_pass_show ? 'eye-slash-fill' : 'eye-fill'" @click="conditions.check_password_pass_show=!conditions.check_password_pass_show" />
                    </span>
                  </div>
                  <template v-if="(forms.check_password.submitted && $v.forms.check_password.password.$invalid) || (api.check_password.error.errors && api.check_password.error.errors.password)">
                    <b-icon id="su-email" icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                    <div class="errorTemplate-v2">
                      <template v-if="forms.check_password.submitted">
                        <p v-if="$v.forms.check_password.password.$invalid" class=" animated bounceIntop">
                          <span v-if="!$v.forms.check_password.password.required">* Password is required</span>
                          <span v-if="!$v.forms.check_password.password.minLength">* Password is minimum 8 is required</span>
                          <span v-else-if="!$v.forms.check_password.password.maxLength">* Password is maximum 32 is required</span>
                        </p>
                      </template>
                      <template v-if="api.check_password.error.errors">
                        <p v-if="api.check_password.error.errors.password" class="animated bounceIntop">
                          <span v-for="(error_message, index) in api.check_password.error.errors.password" :key="index">* {{ error_message }}</span>
                        </p>
                      </template>
                    </div>
                  </template>
                </div>

                <div class="formSubmit mt-32px">
                  <div class="button-container-v2">
                    <div class="button-container-inner">
                      <b-button :disabled="api.check_password.send" class="login-button-v2" type="submit" >
                        <app-spinner v-if="api.check_password.send" />
                        <template v-else>Join</template>
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="login-bottom-line-container with-text fixed-bottom position-absolute">
                  <div class="login-bottom-line-before"></div>
                  <div class="login-bottom-line">
                    <span class="">Back to </span>
                    <a @click="$router.push({name:'login'});" class="mx-2 font-weight-bold font-size-14 color-primary">Sign In</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
  <!-- conference -->
  <div v-else-if="conditions.screen==screens.conference" class="conference_v2 " style="height: 100vh;width: 100vw;position: fixed;top: 0;right: 0;z-index: 999;background-color: white;">
    <Video v-for="runningcall in GET_JITSIMEET.runningcalls" :key="runningcall.id" :call="runningcall" />
  </div>
  <!-- loading -->
  <LoaderMain v-else-if="conditions.screen==screens.loading" />
  <!-- permissions -->
  <div v-else-if="conditions.screen==screens.permissions"> 
    <div class="dialer-form-inner conferences_permissions bg-transparent p-0">
      <div class="callstartup-container">
        <div class="logo-container">
          <img src="../assets/images/VOIP-Phone-Business-Logo.svg"/>
        </div>
        <h4 class="">Welcome User</h4>
        <p>
          In order to enjoy Voip Business conference features 
          to their fullest you must activate your device's 
          microphone and camera.
        </p>
        <div class="video-container">
          <div class="video">
            <div class="video-actions-container">
              <!-- put buttons here -->
              <div @click="data.permissions.mic=='prompt'?data.permissions.getMicPermission():''" :class="`mic-button ${data.permissions.mic=='granted' ? 'active' : data.permissions.mic=='denied' ? 'block' : ''}`">
                <b-icon  :icon="data.permissions.mic=='granted'?'mic-fill':'mic-mute-fill'" />
              </div>
              <div @click="data.permissions.camera=='prompt'?data.permissions.getCameraPermission():''" :class="`camera-button ${data.permissions.camera=='granted' ? 'active' : data.permissions.camera=='denied' ? 'block' : ''}`">
                <b-icon  :icon="data.permissions.camera=='granted'?'camera-video-fill':'camera-video-off-fill'" />
              </div>
            </div>
          </div>
        </div>
        <div class="formSubmit w-100 mt-40px">
          <div class="button-container-v2">
            <div class="button-container-inner">
              <b-button @click="joinConference()" class="login-button-v2 w-100" type="submit" >
                Join Meeting
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- callstartup -->
  <div v-else-if="conditions.screen==screens.callstartup" class="callstartup_v2" style=" background-color: white; border-radius: 25px;">
    <div  class="dialer-form-inner bg-transparent p-0 mb-2">
      <div class="d-flex align-items-stretch align-content-stretch" style="height:416px;min-height:416px;">
        <div class="w-50 p-2 startup-video-container">
          <video v-show="!!streams.video" class="wd-fill-available" id="video_stream" autoplay />
          <template v-if="!streams.video">
            <div class="wd-fill-available videoNotAvailable d-flex justify-content-center align-items-center" style="border-radius: 25px;width:534px;height:400.5px;background-color:#000000a3;">
              <h4 class="opacity-1 color-white">{{  api.video_stream.error_message || 'Camera is Disabled' }}</h4>
            </div>
          </template>
          <audio v-show="!!streams.audio" />
        </div>
        <div class="w-50 p-5 position-relative">
          <div>
            <div class="p-4 audio-checkbox-container d-flex mb-4">
              <div class="w-25">
                <b-icon :icon="conditions.startup_call.audio?'mic-fill':'mic-mute-fill'" />
              </div>
              <div class="w-75">
                <h4>Microphone</h4>
                <span>Allow Microphone</span>
              </div>
              <div class="w-25 d-flex justify-content-end big-checkbox align-items-center">
                <b-form-checkbox v-model="conditions.startup_call.audio" switch />
              </div>
            </div>
            <div class="p-4 audio-checkbox-container d-flex">
              <div class="w-25">
                <b-icon :icon="conditions.startup_call.video?'camera-video-fill':'camera-video-off-fill'" />
              </div>
              <div class="w-75">
                <h4>Camera</h4>
                <span>Allow Camera</span>
              </div>
              <div class="w-25 d-flex justify-content-end big-checkbox align-items-center">
                <b-form-checkbox v-model="conditions.startup_call.video" switch />
              </div>
            </div>
          </div>
          <div class="formSubmit w-100 mt-3 fixed-bottom position-absolute px-5 pb-4">
            <b-button @click="joinConference()" class="login-button-v2 w-100 " type="submit" >
              Join
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- thank_u -->
  <div v-else-if="conditions.screen==screens.thank_u">
    <div class="dialer-form-inner signupLoginV2 emailVerified d-flex bg-transparent p-0 ">
      <div class="signupLoginV2-container after_conference_thank_u">
        <div class="signupLoginV2-section-1 invitationEmailVerified "></div>
        <div class="signupLoginV2-section-2">
          <div class="wrap">
            <div class="signupLoginV2-section-2-left">
              <span class="signupLoginV2-sub-heading">Welcome to VoIP Business</span>
              <h4 class="signupLoginV2-main-heading">
                <span>Thank You!</span><br/>
                <span>For Participating</span><br/>
              </h4>
              <span class="signupLoginV2-sub-heading color-primary text-adjust mt-64px">
                We are grateful for using VoIP Business 
                for attending this Conference.
              </span>
            </div>
            <div class="signupLoginV2-section-2-right">
              <img src="../assets/images/v2_signup_images/emailVerified.png" alt="icon" class="w-100"/>
              <div class="login-bottom-line-container with-text fixed-bottom position-absolute" style="padding: 0px 106px 18px 64px;">
                <div class="login-bottom-line-before"></div>
                <div class="login-bottom-line">
                  <span class="font-size-14">Back to</span>
                  <a @click="$router.push({name:'login'})" class="mx-2 font-weight-bold font-size-14">Sign In</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { 
  required, 
  // email, 
  minLength, 
  maxLength 
} from "vuelidate/lib/validators";
import Video from '../components/video/index.vue'
import LoaderMain from '../components/LoaderMain.vue'
import { API, } from "../service";
import _ from 'lodash'
import { MEDIA_DEVICES, PERMISSIONS } from '../utils';
import { LOGGER } from '../service/index';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import { GET_JITSIMEET } from '@/store/constants/getters';

const screens = {
  meeting: 'meeting',
  check_password: 'check_password',
  conference: 'conference',
  loading: 'loading',
  callstartup: 'callstartup',
  permissions: 'permissions',
  thank_u: 'thank_u',
}
export default {
  name: 'Conference',
  components: {
    Video,
    LoaderMain,
  },
  data(){
    return {
      forms: {
        meeting: {
          meeting_id: '',
          reset(){
            this.meeting_id = ''
          },
          submitted: false,
        },
        check_password: {
          username: '',
          password: '',
          reset(){
            this.username = ''
            this.password = ''
          },
          submitted: false,
        },
      },
      conditions: {
        screen: screens.meeting,
        check_password_pass_show: false,
        startup_call: {
          video: true,
          audio: true,
        },
      },
      api: {
        meeting: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.error_message = ''
          },
        },
        check_password: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.error_message = ''
          },
        },
        video_stream: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.error_message = ''
          },
        },
      },
      data: {
        token: '',
        jitsi_url: '',
        permissions: {},
        devices: {},
      },
      streams: {
        audio: null,
        video: null,
      },
      response: {
        roomdetail: {},
        checkpassword: {},
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_JITSIMEET
    ]),
    screens(){return screens},
  },
  watch: {
    "conditions.screen": {
      immediate: true,
      handler(screen){
        this.$emit('screen',screen)
        if(screen==screens.callstartup){
          if(this.conditions.startup_call.video){
            this.makevideoStream()
          }
        }
      }
    },
    "conditions.startup_call.video"(value){
      if(value){
        this.makevideoStream()
      } else {
        if(this.streams.video){
          this.streams.video.getTracks().forEach((track) => track.stop());
          this.streams.video = null
        }
      }
    },
    "GET_JITSIMEET.runningcalls"(runningcalls){
      if(_.isEmpty(runningcalls) && this.conditions.screen==screens.conference){
        this.conditions.screen=screens.thank_u
      }
    },
    "data.token"(token){
      LOGGER.watch(PERMISSIONS.mic!='granted',token)
      if(token){
        if(PERMISSIONS.mic!='granted'){
          this.conditions.screen=screens.permissions
        } else {
          this.conditions.screen=screens.callstartup
        }
      }
    },
  },
  validations: {
    forms: {
      meeting: {
        meeting_id: {
          required,
        },
      },
      check_password: {
        username: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(15),
        },
        password: {
          minLength: minLength(8),
          maxLength: maxLength(32),
        },
      },
    },
  },
  methods: {
    getroomdetail(){
      let vm = this
      vm.forms.meeting.submitted=true
      vm.$v.forms.meeting.$touch()
      if(vm.api.meeting.send || vm.$v.forms.meeting.$invalid) return;
      vm.api.meeting.error={}
      vm.api.meeting.error_message=''
      vm.api.meeting.send=true
      API.endpoints.schedule.getroomdetail(this.forms.meeting.meeting_id)
      .then(({ data: roomdetail })=>{
        
        const start_time = roomdetail?.schedule?.start_time
        const current_time = moment.utc();
        const expire_time = moment.utc(start_time, 'YYYY-MM-DD hh:mm:ss');
        const diff = expire_time.diff(current_time, 'seconds');
        console.log(diff,current_time.format('YYYY-DD-MM kk:mm:ss'),expire_time.format('YYYY-DD-MM kk:mm:ss'))
        if(diff>0){
          throw new Error('Meeting is not started right know')
        } else {
          vm.response.roomdetail=roomdetail
          if(roomdetail.required_password){
            const { username, password } = vm.forms.check_password
            if(username && password){
              vm.checkpassword()
            } else {
              vm.conditions.screen=screens.check_password
            }
          } else {
            vm.data.token=roomdetail.jwt
            vm.data.jitsi_url=roomdetail.jitsi_url
          }
        }
      }).catch((ex)=>{
        console.log({ex})
        if(vm.conditions.screen!=screens.meeting){
          vm.conditions.screen=screens.meeting
        }
        vm.api.meeting.error = ex.response_error || {}
        if(ex.message.includes('not started')){
          vm.api.meeting.error_message=ex.message
        } else if(_.get(ex,'response.status')==404){
          vm.api.meeting.error_message='Meeting id is invalid'
          _.set(vm.api.meeting.error,'errors.meeting_id',['Meeting id is invalid'])
        } else {
          vm.api.meeting.error_message= ex.own_message || ''
        }
      }).finally(()=>{
        vm.api.meeting.send=false
        vm.forms.meeting.submitted=false
      })
    },
    checkpassword(){
      let vm = this
      vm.forms.check_password.submitted=true
      vm.$v.forms.check_password.$touch()
      if(vm.api.check_password.send || vm.$v.forms.check_password.$invalid) {
        return;
      }
      vm.api.check_password.error={}
      vm.api.check_password.error_message=''
      vm.api.check_password.send=true
      API.endpoints.schedule.checkroompassword(this.forms.meeting.meeting_id,{
        invite_url: vm.response.roomdetail.invite_url,
        username: vm.forms.check_password.username,
        password: vm.forms.check_password.password,
      }).then(({  data: checkpassword })=>{
        const schedule_sec = checkpassword.schedule_sec || 0
        if(schedule_sec>0){
          throw new Error('Meeting is not started right know')
        } else {
          vm.response.checkpassword=checkpassword
          vm.data.token=checkpassword.jwt
          vm.data.jitsi_url=checkpassword.jitsi_url
        }
      }).catch((ex)=>{
        if(vm.conditions.screen!=screens.check_password){
          vm.conditions.screen=screens.check_password
        }
        vm.api.check_password.error = ex.response_error || {}
        if(ex.message.includes('not started')){
          vm.api.check_password.error_message=ex.message
        } else if(_.get(ex,'response.data.status_code')==404){
          vm.api.check_password.error_message='Meeting id is invalid'
        } else {
          vm.api.check_password.error_message= ex.own_message || ''
        }
      }).finally(()=>{
        vm.api.check_password.send=false
        vm.forms.check_password.submitted=false
      })
    },
    joinConference(){
      this.conditions.screen=screens.conference
      if(this.streams.video){
        this.streams.video.getTracks().forEach((track) => track.stop());
        this.streams.video = null
      }
      this.GET_JITSIMEET.joinconference({
        url: this.data.jitsi_url,
        meeting_id: this.forms.meeting.meeting_id,
        token: this.data.token,
        call_type: `${this.conditions.startup_call.video?'video':''}:${this.conditions.startup_call.audio?'audio':''}`,
        conference_name: this.response?.roomdetail?.title ?? '',
        phone_number: this.response?.roomdetail?.phone_number ?? '',
      })
    },
    makevideoStream(){
      let vm = this
      if(vm.api.video_stream.send) return;
      vm.api.video_stream.send=true
      vm.api.video_stream.error_message=''
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          deviceId: {
            exact: vm.data.devices.camera_id
          },
        }
      }).then((stream)=>{
        if(vm.streams.video){
          vm.streams.video.getTracks().forEach((track) => track.stop());
          vm.streams.video=null
        }
        vm.streams.video=stream
        document.getElementById('video_stream').srcObject = vm.streams.video
      }).catch((ex)=>{
        LOGGER.danger({ex})
        if(vm.data.permissions.camera=='denied'){
          vm.api.video_stream.error_message='Your camera Permissions is Blocked'
        } else if(!vm.data.devices.camera_id){
          vm.api.video_stream.error_message='You dont have camera device'
        } else if(ex.name=='NotReadableError'){
          vm.api.video_stream.error_message='Your camera device is in use by another application'
        } else if(_.get(ex,'message')){
          vm.api.video_stream.error_message=_.get(ex,'message')
        } else {
          vm.api.video_stream.error_message='There is something went wrong in starting the video camera'
        }
      }).finally(()=>{
        vm.api.video_stream.send=false
      })
    },
    makeAudioStream(){
      //TODO
    },
    onchangeMicPermission(permission){
      if(this.conditions.screen==screens.permissions && permission=='granted'){
        this.conditions.screen=screens.callstartup
      } else if(this.conditions.screen==screens.callstartup && (permission=='denied' || permission=='prompt')) {
        this.conditions.screen=screens.permissions
      }
      if(permission=='denied' || permission=='prompt'){
        this.conditions.startup_call.mic=false
      }
    },
    onchangeCameraPermission(permission){
      LOGGER.event(permission)
      if(permission=='denied' || permission=='prompt'){
        this.conditions.startup_call.video=false
      } else if(this.conditions.screen==screens.callstartup && this.conditions.startup_call.video) {
        this.makevideoStream()
      }
    },
    onchangeCameraID(){
      LOGGER.event('onchangeCameraID')
      if(this.conditions.screen==screens.callstartup && this.conditions.startup_call.video) {
        this.makevideoStream()
      }
    },
  },
  created(){
    this.data.permissions=PERMISSIONS
    this.data.devices=MEDIA_DEVICES
    MEDIA_DEVICES.on(MEDIA_DEVICES.events.camera_id_change,this.onchangeCameraID)
    PERMISSIONS.on(PERMISSIONS.events.change_mic_permission,this.onchangeMicPermission)
    PERMISSIONS.on(PERMISSIONS.events.change_camera_permission,this.onchangeCameraPermission)
    const { meeting_id, password, username, } = this.$route.query
    this.forms.meeting.meeting_id=meeting_id || ''
    this.forms.check_password.password=password || ''
    this.forms.check_password.username=username || ''
  },
  mounted(){
    const { meeting_id } = this.forms.meeting
    if(meeting_id){
      this.conditions.screen=screens.loading
      this.getroomdetail()
    }
  },
  destroyed() {
    MEDIA_DEVICES.off(MEDIA_DEVICES.events.camera_id_change,this.onchangeCameraID)
    PERMISSIONS.off(PERMISSIONS.events.change_mic_permission,this.onchangeMicPermission)
    PERMISSIONS.off(PERMISSIONS.events.change_camera_permission,this.onchangeCameraPermission)
  },
}
</script>

<style lang="scss">
.login-button-v2.btn-secondary{
  background-color: #5576d1;
  border-color: #5576d1;
  &:active,&:focus{
    background-color: #5576d1;
    border-color: #5576d1;
    box-shadow:none;
  }
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
}
</style>
