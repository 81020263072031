<template>
  <div 
    class="info mainModal" 
    :class="{
      'bill-white-board-info': isWhiteBoard,
      'no-page': !page,
    }"
  >
    <div class="modalHeading-container">
      <div class="modalHeading">
        <template v-if="page == 'participants'">Info</template>
        <template v-else>{{page}}</template>
      </div>
      <b-icon @click="$emit('close')" icon="x" scale="1.3" />
    </div>
    <JitsiChat v-if="page=='chat'" :call="call" />
    <JitsiParticipants v-if="page=='participants'" :call="call" />
    <Whiteboard :style="`visibility: ${page=='whiteboard' ? 'unset' : 'hidden'};`" :class="`${page=='whiteboard' ? '' : 'd-none'}`" :call="call" />
  </div>
</template>

<script>
import JitsiChat from './chat/index.vue'
import JitsiParticipants from './participants/index.vue'
import Whiteboard from './whiteboard/index.vue'
export default {
  name: 'Info',
  components: {
    JitsiChat,
    JitsiParticipants,
    Whiteboard,
  },
  props: {
    call: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    page: {
      type: String,
      default: 'chat'
    },
  },
  computed: {
    isWhiteBoard(){ return this.page=='whiteboard' },
  },
}
</script>

<style lang="scss" scoped>
.info {
  height: inherit;
  width: 20%;
  display: flex;
  background-color: white;
  flex-direction: column;
  height: 100%;
  &.bill-white-board-info {
    width: 40%;
  }
  &.no-page {
    position: absolute;
    z-index: -999;
  }
}
</style>