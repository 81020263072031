<template>
  <div class="main meetings-msg-modal">
    <div class="messages-container">
      <div class="messages">
        <div v-for="(message, index) in call.messages" :key="index" class="meetings-msg-container">
          <Message :message="message" :call="call" />
        </div>
      </div>
    </div>
    <Actions class="action meetings-chatActions-container" :call="call" />
  </div>
</template>

<script>
import Message from './Message.vue'
import Actions from './Actions.vue'
export default {
  name: 'JitsiChat',
  components: {
    Message,
    Actions,
  },
  props: {
    call: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
  },
}
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.messages {
  height: 80%;
  overflow-y: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
}
.action {
  height: 95px;
}
</style>