<template>
  <modal transition="center-zoomIn-transition" :name="modalName" :scrollable="false" class="leaveFeedmackModal performanceSettings shortcutKeysModal">
    <div class="dialer-assign-to dialer-edit w-100">
      <div class="dialer-edit-header d-flex aling-items-center justify-content-between w-100">
        <h2 class="dialer-edit-title mb-0">Shortcut Keys</h2>
        <div class="dialer-edit-actions ">
          <a class="dialer-button dialer-button-delete" type="button" @click="$modal.hide(modalName)">Close</a>
        </div>
      </div>
      <div class="bill-shortcut-keys-container">
        <div class="bill-shortcut-keys-item" v-for="(shortcutkey, index) in shortcutkeys" :key="index">
          <div class="shortcutkeyTitleText">{{shortcutkey.title}}</div>
          <div class="bill-shortcut-keys-item-tags">
            <div class="bill-shortcut-keys-item-tag" v-for="(shortcut, short_cut_index) in shortcutkey.shortcuts" :key="short_cut_index">{{shortcut}}</div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { shortcutkeys } from '../../../jitsimeet/calls/shortcuts'
export default {
  name: "ShortcutKeyModal",
  props: {
    modalName: {
      type: String,
      default: 'ShortcutKeyModal'
    },
    call: {
      type: Object,
      default: ()=>{return{}},
      require: true,
    },
  },
  computed: {
    shortcutkeys(){ return shortcutkeys },
  },
};
</script>

<style lang="scss" scoped>
.bill-shortcut-keys-container {
  .bill-shortcut-keys-item {
    display: flex;
    width: 100%;
    color: white;
    justify-content: space-between;
    margin-top: 15px;
    // border-bottom: 1px solid white;
    border-bottom: 1px solid #434242;
    align-items: center;
  }
  .bill-shortcut-keys-item-tags {
    display: flex;
    .bill-shortcut-keys-item-tag {
      background-color: #333333;
      padding: 5px;
      margin-right: 5px;
    }
  }
}
</style>