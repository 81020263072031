<template>
  <div class="main-container-bill-22">
    <video :id="`testing-video-${_uid}`" autoplay />
    <div v-if="!!error" class="error-message-bill-22">{{error}}</div>
  </div>
</template>

<script>
import { GET_MEDIA_DEVICES, GET_PERMISSIONS } from '@/store/constants/getters'
import { mapGetters } from 'vuex'
export default {
  name: 'CameraTesting',
  props: {
    deviceId: {
      type: String,
    },
  },
  data(){
    return {
      error: '',
      streams: null
    }
  },
  computed: {
    ...mapGetters([
      GET_PERMISSIONS,
      GET_MEDIA_DEVICES
    ]),
  },
  watch: {
    "deviceId": "watchindDeviceId"
  },
  methods: {
    async watchindDeviceId(id,old_id){
      const video = !id ? true : {
        deviceId: {
          exact: id
        }
      }
      if(old_id){
        this.stopStreams()
      }
      const video_el = document.getElementById(`testing-video-${this._uid}`)
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video,
        })
        if(this._isDestroyed){
          stream?.getTracks?.()?.forEach?.(track=>track.stop())
          return;
        }
        this.streams = stream
        video_el.srcObject = this.streams
      } catch (ex) {
        if(this.GET_PERMISSIONS.camera=='denied'){
          this.error='Your camera Permissions is Blocked'
        } else if(!this.GET_MEDIA_DEVICES.camera_id){
          this.error='You dont have camera device'
        } else if(ex.name=='NotReadableError'){
          this.error='Your camera device is in use by another application'
        } else if(ex?.message){
          this.error=ex?.message
        } else {
          this.error='There is something went wrong in starting the video camera'
        }
      }
    },
    stopStreams(){
      this.streams?.getTracks?.()?.forEach?.(track=>track.stop())
    },
  },
  mounted(){
    this.watchindDeviceId()
  },
  beforeDestroy(){
    this.stopStreams()
  },
}
</script>

<style lang="scss" scoped>
// bill
.main-container-bill-22 {
  position: relative;
  height: 100%;
  width: 100%;
  video {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
  }
}
.error-message-bill-22 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: white;
  background-color: #666666;
  border-radius: 16px;
}
</style>