<template>
  <div>
    <div 
      class="participants-container vh-100" 
      :class="{
        'd-flex align-content-start justify-content-center flex-wrap many-participants': call.participants.length > 0,
      }" 
      :participants="call.participants.length+1"  
      style="height:inherit;width:inherit;overflow:auto;"
      id="participantsContainer"
    >
      <Track :call="call" class="" :style="`height:${video_height}px;width:${video_width}px;min-height:${min_video_height}px;min-width:${min_video_width}px;`"/>
      <Track
        v-for="participant in call.participants"
        :key="participant._id"
        :participant="participant"
        :call="call"
        :style="`height:${video_height}px;width:${video_width}px;min-height:${min_video_height}px;min-width:${min_video_width}px;`"
      />
    </div>
  </div>
</template>

<script>
import { LOGGER } from '../../../service';
import Track from './track.vue'
export default {
  name: "Participants",
  components: {
    Track,
  },
  props: {
    call: {
      type: Object,
      default: ()=>{return{}},
      require: true,
    },
  },
  data() {
    return { 
      width: window.innerWidth, 
      height: window.innerHeight, 
      newWidth:0,
      newHeight:0,
      video_width : window.innerWidth,
      video_height : window.innerHeight,
      min_video_width : 0,
      min_video_height : 0,
    };
  },
  watch: {
    "call.participants.length"(length){
      LOGGER.log('call.participants.length',length)
      this.onResize()
    },
  },
  methods: {
    onResize() {
      if(this.call.participants.length==0) {
        this.video_width= window.innerWidth
        this.video_height= window.innerHeight
        return;
      }
      LOGGER.log("Offset width = " + document.getElementById("participantsContainer").offsetWidth);
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      // let num_participants = 61;
      let num_participants = this.call.participants.length + 1;
      let func_width = this.width - 48 - 48;
      let func_height = this.height;
      
      LOGGER.log(this.width + " full " + this.height);
      LOGGER.log(num_participants);
      // this.video_width = Math.round(func_width / num_participants);
      // this.video_height = Math.round((func_height / num_participants) + 50);
      if(num_participants < 4){
        LOGGER.log("hhhh 0");
        if(num_participants == 2){
          this.video_width = Math.round(func_width / num_participants);
          this.video_height = Math.round((func_height / num_participants) - 50);
          this.min_video_width = this.video_width;
          this.min_video_height = this.video_height;
        }
        else if(num_participants == 3){
          this.video_width = Math.round(func_width / num_participants - 1);
          this.video_height = Math.round((func_height / (num_participants - 1)) - 50);
          this.min_video_width = this.video_width;
          this.min_video_height = this.video_height;
        }
      }
      else if(num_participants == 4){
        num_participants = num_participants - 1;
        this.video_width = Math.round(func_width / num_participants);
        this.video_height = Math.round((func_height / num_participants) + 80);
        this.min_video_width = this.video_width;
        this.min_video_height = this.video_height;
      }
      else if(num_participants > 4){
        LOGGER.log("hhhh 2");
        // let columns = Math.sqrt(num_participants);
        let columns = Math.floor( Math.sqrt(num_participants));
        let lines = Math.ceil(num_participants / columns);
        let now_h = 0;
        let now_w = 0;
        LOGGER.log("total column = " + columns);
        LOGGER.log("total row = " + lines);
        num_participants = Math.floor(columns + lines) - Math.floor((columns + lines)/2 ) + 1;
        // this.video_width = Math.round(func_width / num_participants);
        if( columns > lines){
          now_h = lines;
          now_w = columns;
        }
        else if( columns < lines ){
          now_h = columns;
          now_w = lines;
        }
        else{
          now_h = columns;
          now_w = lines;
        }
        this.video_width = Math.round(func_width / (now_w + 1));
        this.video_height = Math.round((func_height / now_h))  - 30;
        this.min_video_width = this.video_width;
        this.min_video_height = this.video_height;
        LOGGER.log(num_participants);
        LOGGER.log(func_width);
        LOGGER.log(now_w);
        LOGGER.log(now_h);

      }
      LOGGER.log(this.video_width + " calculated " + this.video_height);
      // let columns = Math.sqrt(num_participants);
      // let lines = Math.ceil(num_participants / columns);

      // LOGGER.log("total column = " + columns);
      // LOGGER.log("total row = " + lines);
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted(){
    this.onResize()
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
.many-participants{
  padding:8px 48px;
  // background-color: #6B6969;
  background-color: #040404;
  & > div{
    min-width:440px;
    min-height:300px;
    flex:none !important;
    // height:350px;
    height:auto;
    width:auto;
    margin:8px 8px;
    border-radius:12px;
    box-shadow:0 1px 5px rgba(0, 0, 0, 0.15);
    &.participant-container{
      width:auto;
      // height:350px;
      height:auto;
      min-height:300px;
      flex:none;
      & > div:first-child{
        width:100%;
        height:100%;
      }

    }
  }
}

</style>