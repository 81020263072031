export const audio_visualizing = {
  audioStream: null,
  audioContent: null,
  analyser: null,
  stop(){ // binding
    // binding.def.audioStream.disconnect(binding.def.analyser);
    // binding.def.audioContent.close();
  },
  createVisualizing(el,stream,binding){
    if(!stream) return;
    binding.def.audioContent = new AudioContext();
    binding.def.audioStream = binding.def.audioContent.createMediaStreamSource(stream);
    binding.def.analyser = binding.def.audioContent.createAnalyser();
    binding.def.audioStream.connect(binding.def.analyser);
    binding.def.analyser.fftSize = 1024;
    var frequencyArray = new Uint8Array(binding.def.analyser.frequencyBinCount);
    function visulize() {
      requestAnimationFrame(visulize);
      binding.def.analyser.getByteFrequencyData(frequencyArray);
      binding.def?.visulizing?.(el,frequencyArray)
    }
    requestAnimationFrame(visulize);
  },
  inserted(el,binding){
    const stream = binding.value
    binding.def.createVisualizing(el,stream,binding)
  },
  update(el,binding){
    const stream = binding.value
    binding.def.stop(binding)
    binding.def.createVisualizing(el,stream,binding)
  },
  unbind(el,binding){
    binding.def.stop(binding)
  },
}