<template>
  <div class="bill-white-board-main">
    <div :id="call.elIds.white_board" class="bill-white-board">
      <canvas :id="call.elIds.white_board_canvas" />
    </div>
    <ToolBar v-if="call.white_board" :call="call" />
  </div>
</template>

<script>
import ToolBar from './ToolBar.vue'
export default {
  name: 'WhiteBoard',
  components: {
    ToolBar,
  },
  props: {
    call: {
      type: Object,
      default: ()=>{return{}}
    },
  },
}
</script>

<style lang="scss">
.bill-white-board-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 90%;
  .bill-white-board {
    border: 2px solid #5576D1;
    margin-left: 5px;
    width: 90%;
    border-right: 0;
  }
}
</style>