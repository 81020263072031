<template>
  <modal transition="center-zoomIn-transition" :name="modalName" :scrollable="false" class="leaveFeedmackModal performanceSettings">
    <div class="dialer-assign-to dialer-edit w-100">
      <div class="dialer-edit-header d-flex aling-items-center justify-content-between w-100">
        <h2 class="dialer-edit-title mb-0">Performance settings</h2>
        <div class="dialer-edit-actions ">
          <a class="dialer-button dialer-button-delete" type="button" @click="$modal.hide(modalName)">Close</a>
        </div>
      </div>
      <b-form @submit.prevent="''">
        <div class="AdjustForText">Adjust for:</div>
        <div class="performanceSlider-container">
          <div class="performanceSlider-header">
            <div class="performanceSlider-heading">Highest quality</div>
            <div class="performanceSlider-heading">Best performance</div>
          </div>
          <div class="performanceSlider-inner-container">
            <div class="performanceSlider-inner">
              <div 
                v-for="performanceSetting in call.performanceSettings" 
                :key="performanceSetting.value" 
                @click="call.performanceSetting=performanceSetting.value" 
                class="performanceSlider-circle" 
                :class="{
                  'selected': call.performance_setting==performanceSetting.value,
                }"
              />
            </div>
          </div>
          <div class="performanceSlider-text-container">
            <div class="performanceSlider-text" @click="call.performanceSetting=performanceSetting.value" v-for="performanceSetting in call.performanceSettings" :key="performanceSetting.value">
              {{performanceSetting.value}}  
            </div> 
          </div>
        </div>
      </b-form>
    </div>
  </modal>
</template>

<script>
export default {
  name: "PerformanceSettingModal",
  props: {
    modalName: {
      type: String,
      default: 'PerformanceSettingModal'
    },
    call: {
      type: Object,
      default: ()=>{return{}},
      require: true,
    },
  },
};
</script>

<style>
</style>