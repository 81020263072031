<template>
  <div :id="call.elIds.main" style="height:inherit;width:inherit;">
    <div v-if="call.is_connected" style="height:inherit;width:inherit;">
      <Conference :call="call" />
    </div>
    <div v-else>
      <!-- connecting...  -->
      <app-spinner class="big vertically-centered" /> 
    </div>
  </div>
</template>

<script>
import Conference from './Conference.vue'
export default {
  name: 'Connection',
  components: {
    Conference,
  },
  props: {
    call: {
      type: Object,
      default: ()=>{return{}},
      require: true,
    },
  },
  mounted(){
    this.call.makeconnection()
  },
}
</script>

<style>

</style>