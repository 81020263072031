<template>
  <div class="conference_v2_actions v3 bill-main">
    <div class="actions-container">
      <div v-if="conditions.more" class="v2_actions_container more-actions-container">
        <div class="v2_buttons_container">
          <div class="actions_button ml-8px">
            <div class="item" @click="$modal.show('MeetingSettingsModal')">
              <app-icon icon="meetings-settings-icon" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">
            <div class="item" @click="$modal.show('ShortcutKeyModal')">
              <app-icon icon="meetings-shortcutKeys-icon-v2" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">
            <div class="item" @click="feedBack()">
              <app-icon icon="meetings-leaveFeedback-icon" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">
            <div class="item" @click="$modal.show('PerformanceSettingModal')">
              <app-icon icon="meetings-performanceSettings-icon" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">
            <div class="item" @click="copyLink()">
              <app-icon icon="meetings-copyInvitationLink-icon" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">
            <div class="item" @click="$emit('toggle-chat')">
              <app-icon :icon="chat?'meetings-chat-icon':'meetings-chat-icon'" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">
            <div class="item" @click="$emit('toggle-participants')">
              <app-icon :icon="participants?'meetings-addPeople-icon':'meetings-addPeople-icon'" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">                      
            <div class="item" @click="call.toggleFullScreen()">
              <app-icon class="screenSizeIcon" :icon="call.is_full_screen?'meetings-halfScreen-icon':'meetings-fullScreen-icon'" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">
            <div class="item" @click="call.toogleHold()">
              <app-icon :class="call.is_hold?'playIcon':'pauseIcon'" :icon="call.is_hold?'meetings-play-icon':'meetings-pause-icon'" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px"> 
            <div class="item" @click="call.toggleAudioSharing()">
              <app-icon :icon="call.audio.desktop?'meetings-cancelShareAudio-icon':'meetings-shareAudio-icon'" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">
            <div class="item" @click="call.toggleScreenSharing()">
              <app-icon :icon="call.video.desktop?'meetings-cancelShareScreen-icon':'meetings-shareScreen-icon'" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">
            <div class="item" @click="call.toggleRecord()">
              <app-icon :class="`${call.recorder.is_started?'cancalVideoRecord':'VideoRecord'}`" :icon="call.recorder.is_started?'meetings-cancalVideoRecord-icon':'meetings-VideoRecord-icon'" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button_with_dd">
            <div class="item mr-5">
              <app-icon icon="meetings-changeView-icon" height="45px" width="45px"/>
            </div>
            <b-dropdown class="conference-dd-device changeViewDD newDesignDD" no-caret>
              <template #button-content>
                <app-icon class="meetings-ddArrow-icon" icon="meetings-ddArrow-icon" height="20px" width="20px"/>
              </template>
              <b-dropdown-group>
                <b-dropdown-header>
                  Select View
                </b-dropdown-header>
                <b-dropdown-item 
                  v-for="view in call.views" 
                  :key="view.value" 
                  @click="call.view=view.value"
                >
                  <b-icon v-if="call.view==view.value" icon="check-all" />
                  {{ view.text }}
                </b-dropdown-item>
              </b-dropdown-group>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div class="v2_actions_container">
        <div v-if="call.is_join" class="timer">{{ call.time | duration_format }}</div>
        <div class="v2_buttons_container"> 
          <div class="actions_button_with_dd">
            <div class="item mr-5" @click="call.toggleVideoMute()">
              <app-icon :class="!call.video.mute?'meetings-noVideo-icon':'meetings-withVideo-icon'" :icon="!call.video.mute?'meetings-noVideo-icon':'meetings-withVideo-icon'" height="45px" width="45px"/>
            </div>
            <b-dropdown size="sm" class="conference-dd-device newDesignDD" variant="link" toggle-class="text-decoration-none" style="padding:0px;" no-caret>
              <template #button-content>
                <app-icon class="meetings-ddArrow-icon" icon="meetings-ddArrow-icon" height="20px" width="20px"/>
              </template>
              <b-dropdown-group>
                <b-dropdown-header>
                  <b-icon icon="camera-fill" font-scale="1.3" />Camera
                </b-dropdown-header>
                <b-dropdown-item 
                  v-for="device in GET_MEDIA_DEVICES.videoinputs" 
                  :key="device.deviceId" 
                  @click="device.deviceId==call.video.camera_id?'':changeCameraId(device.deviceId)"
                >
                  <b-icon v-if="device.deviceId==call.video.camera_id" icon="check-all" />{{ device.label }}
                </b-dropdown-item>
              </b-dropdown-group>
            </b-dropdown>
          </div>
          <div class="actions_button_with_dd ml-8px">
            <div class="item" @click="call.toggleAudioMute()">
              <app-icon :class="call.audio.mute?'meetings-withAudio-icon':'meetings-noAudio-icon'" :icon="call.audio.mute?'meetings-withAudio-icon':'meetings-noAudio-icon'" height="45px" width="45px"/>
            </div>
            <b-dropdown size="sm" class="conference-dd-device newDesignDD" variant="link" toggle-class="text-decoration-none" style="padding:0px;" no-caret>
              <template #button-content>
                <app-icon class="meetings-ddArrow-icon" icon="meetings-ddArrow-icon" height="20px" width="20px"/>
              </template>
              <b-dropdown-group>
                <b-dropdown-header>
                  <b-icon icon="mic-fill" font-scale="1.3"/>Mic
                </b-dropdown-header>
                <b-dropdown-item 
                  v-for="device in GET_MEDIA_DEVICES.audioinputs" 
                  :key="device.deviceId" 
                  @click="device.deviceId==call.audio.mic_id?'':changeMicId(device.deviceId)"
                >
                  <b-icon v-if="device.deviceId==call.audio.mic_id" icon="check-all" />{{ device.label }}
                </b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider />
              <b-dropdown-group>
                <b-dropdown-header>
                  <b-icon icon="volume-up-fill" font-scale="1.3" />Speaker
                </b-dropdown-header>
                <b-dropdown-item 
                  v-for="device in GET_MEDIA_DEVICES.audiooutputs" 
                  :key="device.deviceId" 
                  @click="device.deviceId==call.audio.speaker_id?'':changeSpeakerId(device.deviceId)"
                >
                  <b-icon v-if="device.deviceId==call.audio.speaker_id" icon="check-all" />{{ device.label }}
                </b-dropdown-item>
              </b-dropdown-group>
            </b-dropdown>
          </div>
          <div class="actions_button_with_dd ml-8px">
            <div class="item" @click="call.handRise()">
              <app-icon class="meetings-hand-icon" icon="meetings-hand-icon" height="45px" width="45px"/>
            </div>
            <b-dropdown size="sm" class="conference-dd-device newDesignDD forEmojis" variant="link" toggle-class="text-decoration-none" style="padding:0px;" no-caret>
              <template #button-content>
                <app-icon class="meetings-ddArrow-icon" icon="meetings-ddArrow-icon" height="20px" width="20px"/>
              </template>
              <div class="dropdown-header inDiv">
                <b-icon icon="emoji-smile" fon-scale="1.3" />React
              </div>
              <b-dropdown-group>
                <b-dropdown-item v-for="emoji in call.emojis" :key="emoji.value" @click="call.react(emoji)">
                  <div style="font-size:35px;">{{emoji.icon}}</div>
                </b-dropdown-item>
              </b-dropdown-group>
            </b-dropdown>
          </div>
          <div class="actions_button ml-8px">
            <div v-if="!call.is_leave" class="item hangout" @click="call.leave()">
              <app-icon class="meetings-hangUp-icon" icon="meetings-hangUp-icon" height="45px" width="45px"/>
            </div>
          </div>
          <div class="actions_button ml-8px">
            <div :class="`item ${conditions.more?'putActiveArrow':''} ${conditions.more?'putActiveArrow':''} call.is_hold`" @click="conditions.more=!conditions.more">
              <app-icon icon="meetings-extras-icon" :class="`meetingsExtrasIcon ${conditions.more?'active':''}`" height="45px" width="45px"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FeedBackModal />
    <PerformanceSettingModal :call="call" />
    <MeetingSettingsModal :call="call" />
    <ShortcutKeysModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LOGGER } from '../../service'
import { shortcut_key_events } from '../../jitsimeet/calls/shortcuts'
import FeedBackModal from '@/components/modals/video/FeedBack.vue'
import PerformanceSettingModal from '@/components/modals/video/PerformanceSetting.vue'
import MeetingSettingsModal from '@/components/modals/video/meetingSettingsModal.vue'
import ShortcutKeysModal from '@/components/modals/video/ShortcutKeys.vue'
import { GET_MEDIA_DEVICES } from '@/store/constants/getters'
// meetingSettingsModal
export default {
  name: 'Actions',
  components: {
    FeedBackModal,
    PerformanceSettingModal,
    MeetingSettingsModal,
    ShortcutKeysModal,
  },
  props: {
    call: {
      type: Object,
      default: ()=>{return{}},
      require: true,
    },
    chat: {
      type: Boolean,
      default: false
    },
    participants: {
      type: Boolean,
      default: false
    },
    white_board: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      conditions: {
        more: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_MEDIA_DEVICES
    ]),
  },
  methods: {
    changeCameraId(deviceId){
      this.call.changeCameraId(deviceId)
    },
    changeMicId(deviceId){
      this.call.changeMicId(deviceId)
    },
    changeSpeakerId(deviceId){
      LOGGER.log(this.call.changeSpeakerId)
      this.call.changeSpeakerId(deviceId)
    },
    feedBack(){
      let vm = this
      this.$modal.show('FeedBackModal',{
        onSubmit({ rate,detail }){
          vm.call.sendFeedBack(rate,detail)
        }
      })
    },
    copyLink(){
      let vm = this
      this.call.copyLink()
      .then(()=>{
        vm.$toast.open({
          type: 'success',
          message: 'Link Copied Successfully'
        })
      })
      .catch((ex)=>{
        vm.$toast.open({
          type: 'error',
          message: ex.message || 'Failed to Copied'
        })
      })
    },
    onShortcutKeyPress(event){
      switch (event) {
        case shortcut_key_events.hang_up:
          this.call.leave()
          break;
        case shortcut_key_events.toggle_audio_mute:
          this.call.toggleAudioMute()
          break;
        case shortcut_key_events.toggle_video_mute:
          this.call.toggleVideoMute()
          break;
        case shortcut_key_events.toggle_hold:
          this.call.toogleHold()
          break;
        case shortcut_key_events.toogle_hand_rise:
          this.call.handRise()
          break;
        case shortcut_key_events.toogle_full_screen:
          this.call.toggleFullScreen()
          break;
        case shortcut_key_events.open_setting:
          this.$modal.show('MeetingSettingsModal')
          break;
        case shortcut_key_events.copy_link:
          this.copyLink()
          break;
        case shortcut_key_events.toogle_chat:
          this.$emit('toggle-chat')
          break;
        case shortcut_key_events.open_shortcuts:
          this.$modal.show('ShortcutKeyModal')
          break;
        case shortcut_key_events.toogle_participants:
          this.$emit('toggle-participants')
          break;
        case shortcut_key_events.speaker_view:
          this.call.view=this.call.views.speaker.value
          break;
        case shortcut_key_events.grid_view:
          this.call.view=this.call.views.grid.value
          break;
        case shortcut_key_events.performance_setting:
          this.$modal.show('PerformanceSettingModal')
          break;
        case shortcut_key_events.recording:
          this.call.toggleRecord()
          break;
        case shortcut_key_events.toogle_white_board:
          this.$emit('toggle-white-board')
          break;
      
        default:
          break;
      }
    },
  },
  mounted(){
    this.call.onshortcutkeypress = this.onShortcutKeyPress
  },
  beforeDestroy(){
    if(this?.call?.onshortcutkeypress) this.call.onshortcutkeypress = null
  },
}
</script>

<style lang="scss" scoped>
.bill-main {
  width: 100% !important;
  .actions-container {
    flex-direction: column;
    display: flex;
    .more-actions-container {
      margin-bottom: 5px;
    }
  }
}
.item {
  background-color:white;
  padding:10px;
  border-radius:50%;
  height:45px;
  width:45px;
  cursor:pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.hangout {
  background-color:red;
  color: white;
}
.item:hover {
  background-color:#333;
  color: white;
}
.timer {
  background-color:white;
  padding:10px;
  border-radius:10px;
}
.mr-5 {
  margin-right:5px;
}
</style>