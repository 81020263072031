import { audio_visualizing } from "../directives/audioVisualizing"
import { attach_track } from "../directives/attachTrack"
let frequency_data_map = [0,15,0];

export const jitsi_participant_info = {
  computed: {
    waves(){ return frequency_data_map },
    isLocal(){ return !this.participant },
    speakerStat(){
      if(this.isLocal) return this.call?.audio?.speaker_stats ?? 0
      return this.participant?._properties?.speaker_stats
    },
    isSpeaker(){ 
      if(this.isLocal) return this.call?.jitsi?.user_id==this.call?.speaker_id
      return this.participant?._id==this.call?.speaker_id
    },
    isRecording(){ 
      if(this.isLocal) return this.call?.recorder?.is_started
      return this.participant?._properties?.recording=='true'
    },
    displayName(){
      if(this.isLocal) return this.call?.jitsi?.payload?.context?.user?.name ?? ''
      return this.participant?._displayName
    },
    avatar(){ 
      if(this.isLocal) return this.call?.jitsi?.payload?.context?.user?.avatar ?? ''
      else return this.participant?._properties?.avatar
    },
    audioLevel(){ 
      if(this.isLocal) return 1
      else return (this.participant?._properties?.audioLevel || 100)/100 
    },
    isMirrorVideo(){ 
      if(this.isLocal) return false
      else return this.participant?._properties?.isMirrorVideo=='true' 
    },
    isHandRise(){ 
      if(this.isLocal) return this.call?.is_hand_rise
      else return this.participant?._properties?.emoji==this.call?.defaultEmoji?.value 
    },
    videoMuted(){ 
      if(this.call?.performance_setting=='none') return true
      else if(this.isLocal) return this.call?.video?.mute
      else return !!(this.videoTrack?.muted || this.participant?._properties?.isVideoMuted=='true') 
    },
    videoErrorMessage(){ 
      if(this.isLocal) return this.call?.video?.error_message 
    },
    audioMuted(){ 
      if(this.isLocal) return this.call?.audio?.mute
      else return this.participant?._properties?.isAudioMuted=='true' || this.audioTrack?.muted 
    },
    isHold(){ 
      if(this.isLocal) return this.call?.is_hold
      else return this.participant?._properties?.isHold=='true' 
    },
    tracks(){
      const tracks = this.participant?._tracks ?? []
      if(!this.call?.performanceSetting?.show_video) return tracks.filter(track=>track.type!='video')
      else return tracks
    },
    videoTrack(){ 
      if(this.isLocal) return this.call?.tracks?.local?.find?.(track=>track.type=='video')
      else return this.tracks.find(track=>track.type=='video') 
    },
    audioTrack(){ 
      if(this.isLocal) return this.call?.tracks?.local?.find?.(track=>track.type=='audio')
      else return this.tracks.find(track=>track.type=='audio') 
    },
  },
  directives: {
    'attach-track': attach_track,
    'visualize': {
      ...audio_visualizing,
      visulizing(el,frequency_array){
        if(frequency_array){
          const values = Object.values(frequency_array);
          frequency_data_map.forEach((val,i)=>{
            const value = (values[val] || 15)/255;
            const c_el = el.childNodes[i]
            if(c_el){
              c_el.style.transform = `scaleY(${value})`;
              c_el.style.opacity = Math.max(.25,value);
            }
          })
        }
      },
    }
  },
}