<template>
  <modal transition="center-zoomIn-transition" :name="modalName" :scrollable="false" @before-open="onBeforeOpen" @before-close="onBeforeClose" class="leaveFeedmackModal">
    <div class="dialer-assign-to dialer-edit w-100">
      <div class="dialer-edit-header d-flex aling-items-center justify-content-between w-100">
        <h2 class="dialer-edit-title mb-0">Feedback</h2>
        <div class="dialer-edit-actions ">
          <a class="dialer-button dialer-button-delete" type="button" @click="$modal.hide(modalName)">Close</a>
        </div>
      </div>
      <b-form @submit.prevent="submit()">
        <b-form-rating size="lg" class="feedBackRating" variant="primary" v-model="forms.feed_back.rate" stars="5"></b-form-rating>
        <b-form-textarea class="dialer-input-field input-consistent-inner-shadows" v-model="forms.feed_back.detail" />
        <div class="dialer-button-container">
          <b-button class="dialer-button dialer-button-delete mr-3" type="button" @click="$modal.hide(modalName)">Cancel</b-button>
          <b-button class="dialer-button dialer-button-primary" type="submit">Submit</b-button>
        </div>
      </b-form>
    </div>
  </modal>
</template>

<script>
export default {
  name: "FeedBackModal",
  props: {
    modalName: {
      type: String,
      default: 'FeedBackModal'
    },
  },
  data() {
    return {
      forms: {
        feed_back: {
          rate: null,
          detail: '',
        },
      },
      data: {
        onSubmit: null
      },
    };
  },
  methods: {
    onBeforeOpen(event) {
      this.data.onSubmit = event.params.onSubmit || null
      this.forms.feed_back.rate = event.params.rate || null
      this.forms.feed_back.detail = event.params.detail || ''
    },
    onBeforeClose() {
      this.forms.feed_back.rate=null
      this.forms.feed_back.detail=''
      this.data.onSubmit = null
    },
    submit(){
      const data = {
        rate: this.forms.feed_back.rate,
        detail: this.forms.feed_back.detail,
      }
      this.data?.onSubmit?.(data)
      this.$emit('submitted',data)
      this.$modal.hide(this.modalName)
    },
  },
};
</script>

<style>
</style>