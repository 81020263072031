<template>
  <div class="speaker-view v2" :tracks="call.participants.length+1" >
    <div class="list">
      <Track class="speaker-list" :call="call" />
      <div class="forListScroll">
        <Track
          class="speaker-list"
          v-for="(participant, index) in call.participants"
          :key="participant._id"
          :class="`bill-track-${index+2}`"
          :participant="participant"
          :call="call"
        />
      </div>
    </div>
    <div class="speaker-container">
      <Track 
        class="speaker-track"
        :participant="call.speaker"
        :call="call"
      />
    </div>
  </div>
</template>

<script>
import Track from './track.vue'
export default {
  name: 'GridView',
  components: {
    Track,
  },
  props: {
    call: {
      type: Object,
      default: ()=>{return{}},
      require: true,
    },
  },
}
</script>

<style lang="scss">
.speaker-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #6B6969;
  height: 100%;
  width: 100%;
  .list {
    display: flex;
    height: 15%;
    .speaker-list {
      min-width: 300px;
      height: 100%;
    }
  }
  .speaker-container {
    display: flex;
    // flex: 1;
    height: 84%;
    width: 100%;
    .speaker-track {
      width: 100%;
    }
  }
}

</style>