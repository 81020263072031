<template>
  <div :class="`meetings-msg-inner ${participant ? 'recieved' : 'sent'}`">
    <div class="meetings-msg-itself">
      <span v-html="message.text"></span>
    </div>
    <template v-if="participant">
      <div class="meetings-msg-senderText">{{ participant._displayName }}</div>
    </template>
    <template v-else>
      <div class="meetings-msg-senderText">me</div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    message: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    call: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
  },
  computed: {
    participant(){ return this.call.participants.find(i=>i._id==this.message.sender) },
  },
}
</script>

<style>

</style>