<template>
  <modal 
    transition="center-zoomIn-transition" 
    :name="modalName" 
    :scrollable="false" 
    @before-open="onBeforeOpen"
    @before-close="onBeforeClose"
    class="leaveFeedmackModal meetingSettingsModal"
  >
    <div class="dialer-assign-to dialer-edit w-100">
      <div class="dialer-edit-header d-flex aling-items-center justify-content-between w-100">
        <h2 class="dialer-edit-title mb-0">Settings</h2>
        <div class="dialer-edit-actions ">
          <a class="dialer-button dialer-button-delete" type="button" @click="$modal.hide(modalName)">Close</a>
        </div>
      </div>
      <div class="tabs-header">
        <div 
          v-for="tab in tabs" 
          :key="tab.value"
          class="tab-name" 
          :class="{
            'selected':selected.tab==tab.value
          }" 
          @click="selected.tab=tab.value"
        >
          {{tab.text}}
        </div>
      </div>
      <div class="tabs-content">
        <template v-if="selected.tab==tabs.devices.value">
          <div class="deviceSettings-continer">
            <div class="camera-container bill-camera-container">
              <Camera :deviceId="call.video.camera_id" />
            </div>
            <div class="inputs-container">
              <div class="inputWithLabel">
                <label>Microphone</label>
                <b-dropdown class="settingsDD" no-caret>
                  <template #button-content>
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="selectedTextContainer">{{call | get_property('selectedMic.label','Selected Microphone Name')}}</div>
                      <b-icon icon="chevron-down" />
                    </div>
                  </template>
                  <b-dropdown-item 
                    v-for="device in GET_MEDIA_DEVICES.audioinputs" 
                    :key="device.deviceId" 
                    @click="device.deviceId==call.audio.mic_id?'':call.changeMicId(device.deviceId)"
                  >
                    <b-icon v-if="device.deviceId==call.audio.mic_id" icon="check-all" />{{ device.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="inputWithLabel withMT">
                <label>Camera</label>
                <b-dropdown class="settingsDD" no-caret>
                  <template #button-content>
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="selectedTextContainer">{{call | get_property('selectedCamera.label','Selected Camera Name')}}</div>
                      <b-icon icon="chevron-down" />
                    </div>
                  </template>
                  <b-dropdown-item 
                    v-for="device in GET_MEDIA_DEVICES.videoinputs" 
                    :key="device.deviceId" 
                    @click="device.deviceId==call.video.camera_id?'':call.changeCameraId(device.deviceId)"
                  >
                    <b-icon v-if="device.deviceId==call.video.camera_id" icon="check-all" />{{ device.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="inputWithLabel withMT">
                <label>Audio output</label>
                <b-dropdown class="settingsDD" no-caret>
                  <template #button-content>
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="selectedTextContainer">{{call | get_property('selectedSpeaker.label','Selected Audio Name')}}</div>
                      <b-icon icon="chevron-down" />
                    </div>
                  </template>
                  <b-dropdown-item 
                    v-for="device in GET_MEDIA_DEVICES.audiooutputs" 
                    :key="device.deviceId" 
                    @click="device.deviceId==call.audio.speaker_id?'':call.changeSpeakerId(device.deviceId)"
                  >
                    <b-icon v-if="device.deviceId==call.audio.speaker_id" icon="check-all" />{{ device.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </template>
        <template v-if="selected.tab==tabs.profile.value">
          <div class="profileSettings-continer">
            <div class="camera-container">
              <div class="inputWithLabel">
                <label>display Name</label>
                <input v-model="forms.profile.display_name" v-app-focus type="text" :maxlength="$v.forms.profile.display_name.$params.maxLength.max" />
                <template v-if="forms.profile.submitted && $v.forms.profile.display_name.$invalid">
                  <span v-if="!$v.forms.profile.display_name.required">display name is required</span>
                  <span v-else-if="!$v.forms.profile.display_name.maxLength">display name should be less then {{$v.forms.profile.display_name.$params.maxLength.max}} character</span>
                </template>
              </div>
            </div>
            <div class="inputs-container">
              <div class="inputWithLabel">
                <label>Image Url</label>
                <input v-model="forms.profile.image_url" type="url" />
                <template v-if="forms.profile.submitted && $v.forms.profile.image_url.$invalid">
                  <span v-if="!$v.forms.profile.image_url.required">Image url is required</span>
                  <span v-else-if="!$v.forms.profile.image_url.url">Image url is invalid url</span>
                </template>
              </div>
            </div>
          </div>
          <div class="dialer-button-container">
            <b-button @click="setProfileInfo()" class="dialer-button dialer-button-primary mr-3">OK</b-button>
            <b-button @click="setProfileValues()" class="dialer-button dialer-button-primary outline">Cancel</b-button>
          </div>
        </template>
        <template v-if="selected.tab==tabs.sounds.value">
          <div class="soundSettings-continer">
            <div class="heading">Play sounds on</div>
            <div class="checkboxContainer">
              <b-form-checkbox v-model="forms.sounds.reaction">Meeting reactions</b-form-checkbox>
              <b-form-checkbox v-model="forms.sounds.incoming_message">Incoming message</b-form-checkbox>
              <b-form-checkbox v-model="forms.sounds.user_joined">Participant Joined</b-form-checkbox>
              <b-form-checkbox v-model="forms.sounds.user_left">Participant Left</b-form-checkbox>
            </div>
          </div>
          <div class="dialer-button-container">
            <b-button @click="setSounds()" class="dialer-button dialer-button-primary mr-3" type="button" >OK</b-button>
            <b-button @click="setSoundsValue()" class="dialer-button dialer-button-primary outline" type="submit">Cancel</b-button>
          </div>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Camera from '../../common/mediaTesting/Camera.vue'
import { required, maxLength, url } from "vuelidate/lib/validators";
import { GET_MEDIA_DEVICES } from '@/store/constants/getters';
let tabs = {
  devices: {
    text: 'Devices',
    value: 'devices',
  },
  profile: {
    text: 'Profile',
    value: 'profile',
  },
  sounds: {
    text: 'Sounds',
    value: 'sounds',
  },
}
export default {
  name: "MeetingSettingsModal",
  components: {
    Camera,
  },
  props: {
    modalName: {
      type: String,
      default: 'MeetingSettingsModal'
    },
    call: {
      type: Object,
      default: ()=>{return{}},
      require: true,
    },
  },
  data() {
    return {
      selected: {
        tab: tabs.devices.value
      },
      forms: {
        sounds: {
          reaction: true,
          incoming_message: true,
          user_joined: true,
          user_left: true,
        },
        profile: {
          display_name: '',
          image_url: '',
          reset(){
            this.display_name=''
            this.image_url=''
            this.submitted=false
          },
          submitted: false
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      GET_MEDIA_DEVICES
    ]),
    tabs(){ return tabs },
    selectedTab(){ return tabs[this.selected.tab]},
  },
  validations: {
    forms: {
      profile: {
        display_name: {
          required,
          maxLength: maxLength(20),
        },
        image_url: {
          required,
          url,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      this.setSoundsValue()
      this.setProfileValues()
    },
    onBeforeClose(){
      this.forms.profile.reset()
    },
    setSoundsValue(){
      this.forms.sounds.reaction=!!this.call?.sounds?.reaction
      this.forms.sounds.incoming_message=!!this.call?.sounds?.incoming_message
      this.forms.sounds.user_joined=!!this.call?.sounds?.user_joined
      this.forms.sounds.user_left=!!this.call?.sounds?.user_left
    },
    setSounds(){
      this.call.setSounds(this.forms.sounds)
      this.$toast.open({
        message: 'Successfully set',
        type: 'success'
      })
    },
    setProfileValues(){
      this.forms.profile.display_name=this.call?.jitsi?.payload?.context?.user?.name ?? ''
      this.forms.profile.image_url=this.call?.jitsi?.payload?.context?.user?.avatar ?? ''
    },
    setProfileInfo(){
      this.forms.profile.submitted=true
      this.$v.forms.profile.$touch()
      if(this.$v.forms.profile.$invalid) return;
      this.call.setProfile({
        display_name: this.forms.profile.display_name,
        image_url: this.forms.profile.image_url,
      })
      this.$toast.open({
        message: 'Successfully set',
        type: 'success'
      })
      this.forms.profile.submitted=false
    },
  },
};
</script>

<style land="scss" scoped>
.bill-camera-container{
  border-radius: 15px;
  overflow: hidden;
  margin-right: 10px;
}
</style>