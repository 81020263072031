<template>
  <ContainView v-if="call.view==call.views.contain.value" :call="call" />
  <SpeakerView v-else-if="call.view==call.views.speaker.value" :call="call" />
  <GridView v-else :call="call" />
</template>

<script>
import GridView from './GridView.vue'
import ContainView from './ContainView.vue'
import SpeakerView from './SpeakerView.vue'
export default {
  name: "Tracks",
  components: {
    GridView,
    ContainView,
    SpeakerView,
  },
  props: {
    call: {
      type: Object,
      default: ()=>{return{}},
      require: true,
    },
  },
};
</script>

<style lang="scss">


</style>