<template>
  <div class="white-board-tool-bar">
    <div :class="{'active': call.white_board.isPencilMode}" v-b-popover.hover.left="'Brush'">
      <b-icon class="cursor_pointer" @click="call.white_board.setMode(call.white_board.modes.pencil.value)" icon="pencil-fill" />
    </div>
    <div :class="{'active': call.white_board.isSelectMode}" v-b-popover.hover.left="'Select'">
      <b-icon class="cursor_pointer" @click="call.white_board.setMode(call.white_board.modes.select.value)" icon="book-fill" />
    </div>
    <div :class="{'active': call.white_board.isEraseMode}" v-b-popover.hover.left="'Erase'">
      <b-icon class="cursor_pointer" @click="call.white_board.setMode(call.white_board.modes.erase.value)" icon="alarm-fill" />
    </div>
    <div v-b-popover.hover.left="'Clear'">
      <b-icon class="cursor_pointer" @click="call.white_board.clear()" icon="trash" />
    </div>
    <div v-b-popover.hover.left="'Download'">
      <b-icon class="cursor_pointer" @click="call.white_board.download()" icon="download" />
    </div>
    <div v-b-popover.hover.left="'Setting'">
      <b-dropdown no-caret dropleft class="bill-drop-down">
        <template #button-content>
          <b-icon icon="chevron-left" />
        </template>
        <b-dropdown-header>General</b-dropdown-header>
        <b-dropdown-form @submit.prevent="''">
          <label>Line Width {{call.white_board.setting.width}}</label>
          <br />
          <b-form-input :value="call.white_board.setting.width" @input="call.white_board.setSetting('width',$event)" type="range" min="0" max="100" step="1" />
          <br />
          <label>Color</label>
          <br />
          <b-form-input :value="call.white_board.setting.color" @change="call.white_board.setSetting('color',$event)" type="color" />
        </b-dropdown-form>
        <b-dropdown-divider />
        <b-dropdown-header>Brush</b-dropdown-header>
        <b-dropdown-form @submit.prevent="''">
          <label>Brush</label>
          <br />
          <b-form-select :value="call.white_board.setting.brush" @change="call.white_board.setSetting('brush',$event)">
            <b-form-select-option v-for="(brushe, key) in call.white_board.brushes" :key="key" :value="key">{{ brushe.text }}</b-form-select-option>
          </b-form-select>
          <br />
          <label>Line Shadow Width {{call.white_board.setting.shadow_width}}</label>
          <br />
          <b-form-input :value="call.white_board.setting.shadow_width" @input="call.white_board.setSetting('shadow_width',$event)" type="range" min="0" max="100" step="1" />
          <br />
          <label>Shadow Offset {{call.white_board.setting.shadow_offset}}</label>
          <br />
          <b-form-input :value="call.white_board.setting.shadow_offset" @input="call.white_board.setSetting('shadow_offset',$event)" type="range" min="0" max="100" step="1" />
          <br />
          <label>Shadow Color</label>
          <br />
          <b-form-input :value="call.white_board.setting.shadow_color" @change="call.white_board.setSetting('shadow_color',$event)" type="color" />
        </b-dropdown-form>
        <b-dropdown-divider />
        <b-dropdown-header>Shapes</b-dropdown-header>
        <b-dropdown-form @submit.prevent="''">
          <label>Shape</label>
          <br />
          <b-form-select :value="call.white_board.shape" @change="call.white_board.setShape=$event">
            <b-form-select-option v-for="shape in call.white_board.shapes" :key="shape" :value="shape">{{ shape }}</b-form-select-option>
          </b-form-select>
          <br />
          <label>Fill Color</label>
          <br />
          <b-form-input :value="call.white_board.setting.fill_color" @change="call.white_board.setSetting('fill_color',$event)" type="color" />
          <br />
          <label>Fill</label>
          <br />
          <b-form-checkbox switch :checked="call.white_board.setting.fill" @change="call.white_board.setSetting('fill',$event)" />
        </b-dropdown-form>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolBar",
  props: {
    call: {
      type: Object,
      default: ()=>{return{}}
    },
  },
  methods: {
  uploadImage(event) {
    const [file] = event.target.files;
    if(!file) return;
    this.call.white_board.uploadImage(file)
  }
  },
}
</script>

<style lang="scss">
$bill-primary-color: #5576D1;
.white-board-tool-bar {
  display: flex;
  flex-direction: column;
  background-color: $bill-primary-color;
  height: auto;
  align-items: center;
  width: 10%;
  div {
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px;
    width: 100%;
    &.active {
      background-color: white;
      color: $bill-primary-color;
      .bill-drop-down {
        button {
          svg {
            color: $bill-primary-color;
          }
        }
      }
    }
    .bill-drop-down {
      padding: 0;
      button {
        background-color: transparent !important;
        border-color: transparent !important;
        outline: none;
        border-width: 0;
        border: 0;
        box-shadow: none;
        padding: 0;
        margin: 0;
      }
      ul {
        min-width: 15rem;
        min-height: 350px;
        height: 350px;
        overflow-y: scroll;
      }
    }
  }
}
</style>