export const attach_track = {
  attach(el,binding){
    const stream_id = el?.srcObject?.id
    const track = binding?.value
    const track_stream_id = track?.stream?.id
    if(track && !(track.isLocal() && track.type=='audio') && track_stream_id!=stream_id) track.attach(el)
  },
  inserted(el,binding){
    binding.def.attach(el,binding)
  },
  update(el,binding){
    binding.def.attach(el,binding)
  },
}