<template>
  <div>
    <b-form class="meetings-chatActions-form" @submit.prevent="submit()">
      <div class="chatInput-container">
        <b-textarea v-app-focus @keypress.enter.exact.prevent="submit()" v-model="forms.message.message" id="chatInput" placeholder="Enter message" @input="findTextLength" no-resize/>
      </div>
      <app-icon @click="submit" icon="meetings-chatSendText-icon" class="sendMessageIcon" height="55px" width="55px"/>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'Action',
  props: {
    call: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
  },
  data(){
    return {
      forms: {
        message: {
          message: '',
        },
      },
      textLength : 0,
    }
  },
  methods: {
    submit(){
      this.call.sendMessage(this.forms.message.message)
      this.forms.message.message=''
      let putI = document.getElementById("chatInput")
      if(putI.classList.contains("increaseHeight1")){
        putI.classList.remove("increaseHeight1")
      }
    },
    findTextLength(){
      let putI = document.getElementById("chatInput")
      this.textLength = this.forms.message.message.length
      if(this.textLength > 35){
        putI.classList.add("increaseHeight1")
      }
    },
  },
}
</script>

<style>

</style>