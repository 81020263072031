<template>
  <div class="participantName">
    <div class="participantName-userInfo">
      <img :src="avatar" >
      <div class="participantName-userName">{{ displayName }}</div>
    </div>
    <div class="participantName-icon">
      <div v-if="isHandRise">{{call.defaultEmoji.icon}}</div>
      <b-icon :icon="audioMuted?'mic-mute-fill':'mic-fill'" class="micIcon" />
      <b-icon :icon="videoMuted?'camera-video-off-fill':'camera-video-fill'" class="cameraIcon" />
      <b-icon v-if="isHold" icon="pause-fill" class="pauseIcon" />
      <b-icon v-if="isSpeaker" icon="play-fill" class="pauseIcon" />
      <span class="speaker-time">{{speakerStat | duration_format}}</span>
      <div v-if="!audioMuted && audioTrack && audioTrack.stream" class="bill-wave-visualize" v-visualize="audioTrack.stream">
        <div v-for="(data,index) in waves" :key="index" :data-value="data" />
      </div>
    </div>
  </div>
</template>

<script>
import { jitsi_participant_info } from '@/bootstrap/mixins/jitsiParticipantInfo'
export default {
  name: 'Participant',
  mixins: [jitsi_participant_info],
  props: {
    call: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    participant: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.bill-main {
  display: flex;
  color: white;
  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}
.speaker-time {
  margin-left: 5px;
  background-color: #5576d1;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}
</style>